import React from "react";
import { Container, Row, Col, Card, Modal, Button } from "react-bootstrap";
import logo from "./logo_icon.png";
import "./App.css";

const PrivacyPolicy = () => {
  return (
    <div className="App">
      <Container>
        <Row>
          <Col md={12} style={{ paddingTop: "12%" }}>
            <header className="App-header">
              <div style={{ display: "inherit" }}>
                <img src={logo} className="App-logo" alt="logo" />
                <p
                  style={{
                    paddingLeft: "10px !important;",
                    fontSize: "48px",
                    margin: "auto",
                    marginLeft: "10px",
                  }}
                >
                   <h1>Pay<span style={{ color: "#ef0362" }}>Plus</span> Privacy Policy</h1>
                </p>
              </div>

              <h3
                style={{
                  width: "80%",
                  textAlign: "center",
                  fontSize: "16px",
                  fontStyle: "italic",
                }}
              >
                We are hiring!
              </h3>

              <Card style={{ width: "80%", marginBottom: "70px" }}>
                <Card.Body style={{textAlign: "left"}}>


      <p><strong>Part 1: Introduction and Scope</strong></p>

      <p>This Privacy Policy explains how PayPlus and WYD Technologies Limited ("we," "us," or "our") collect, use, protect, and disclose your information when you use the PayPlus Services, access our websites, or interact with us. This Privacy Policy also covers our collection, use, and disclosure of information when you use our mobile app, which requires certain permissions on your device.</p>

      <p><strong>Part 2: Information We Collect</strong></p>

      <p>We collect various types of information, such as Personal Data, Account Information, Transaction Information, and other information related to your use of the PayPlus Services. The information we collect may be provided by you, generated during your use of the PayPlus Services, or obtained from third parties.</p>

      <p>When using our mobile app, we may also collect information through the following permissions:</p>
      <ul style={{textAlign: "initial",
    marginLeft: "5%"}}>
        <li>android.permission.GET_ACCOUNTS</li>
        <li>android.permission.READ_PHONE_STATE</li>
        <li>android.permission.READ_CONTACTS</li>
        <li>android.permission.CAMERA</li>
        <li>android.permission.RECORD_AUDIO</li>
      </ul>
      <p>These permissions allow us to provide certain features and functionalities within the app. By using the app, you consent to our collection and use of information through these permissions.</p>

      <p><strong>Part 3: How We Use the Information We Collect</strong></p>

      <p>We use the information we collect to provide, maintain, and improve the PayPlus Services, to personalize your experience, to process transactions, to send you notifications and updates, to prevent fraud, and for other purposes described in this Privacy Policy.</p>

      <p><strong>Part 4: How We Share Your Information</strong></p>

      <p>We may share your information with third parties in connection with the provision of the PayPlus Services, to comply with legal obligations, to prevent fraud, or for other purposes described in this Privacy Policy.</p>

      <p><strong>Part 5: Cookies and Tracking Technologies</strong></p>

      <p>We use cookies and other tracking technologies to recognize you as a user, to customize your online experiences, to measure the effectiveness of promotions, to perform analytics, and to promote trust and safety across our websites and services. You have choices regarding the use of cookies and other tracking technologies.</p>

      <p><strong>Part 6: Your Privacy Choices and Rights</strong></p>

      <p>You have various choices and rights with respect to the privacy practices and communications described in this Privacy Policy. These choices and rights may include opting out of marketing communications, managing your account connections, and accessing or updating your Personal Data.</p>
    
                </Card.Body>
              </Card>
            </header>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export {PrivacyPolicy};