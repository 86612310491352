
import React from 'react';

import { NavLink } from 'react-router-dom';

const Navigation = () => {
    return (<footer>
        <p style={{textAlign: "center"}}>
          <a href={"/"} >Home</a>
          <a href={"https://medium.com/@payplusblog"}>Blog</a>
          <a href={"/careers"}>Careers</a>
          <a href={"/privacy"}>Privacy</a>
          <a style={{ border: "none" }} href={"#"}>Terms</a>
        </p>
      </footer>);
}

export { Navigation };