import React from "react";
import { Container, Row, Col, Card, Modal, Button } from "react-bootstrap";
import logo from "./logo_icon.png";
import "./App.css";

function MydModalWithGrid(props) {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Social Media Marketing - Intern
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          <Row>
            <Col xs={12} md={12}>
              <p style={{ fontSize: "14px"}}>PayPlus is a fintech company with a mission to expand opportunities for social commerce in Africa. 
              We are looking for a creative and self-motivated marketing intern to join our team.</p>
                  Responsibilities
              <ul style={{fontSize: "14px"}}>
              <li>
              Designing surveys to collect data 
                    </li>
                    <li>
                    Implement intuitive marketing strategies for brand exposure
                    </li>
                    <li>
                    Researching consumer trends and opinions to inform marketing strategy
                    </li>
                    <li>
                    Analyzing complex research and representing data through graphics
                    </li>
                    <li>Maintain Social Media platforms of the Company to boost social engagements, promotion of our brand and products.</li>
              </ul>
              Required Skills and Qualifications
              <ul style={{fontSize: "14px"}}>
                <li>
                You are an ambitious individual with keen interest and skills in social media and content marketing.
                </li>
                <li>
                Undergraduate in Marketing/Communications or related fields
                </li>
                <li>Strong verbal and written communication skills</li>
                <li>Excellent research and writing skills, particularly as it applies to marketing.</li>
                <li>Ability to simplify complicated topics with simple language</li>
                <li>Knowledge or experience with Social Media Marketing and Analytics is a plus</li>
                <li>Attention to detail with great organization and time management skills</li>
              </ul>
              <p style={{fontSize: "14px"}}>
                <span style={{color: "#ef0362", fontWeight: "700"}}>Note: </span>This position is remote/hybrid, preferred candidate would be based in Lagos
              </p>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} href={"https://docs.google.com/forms/d/e/1FAIpQLSdFPiZjXrtmqNpd1HV-RU0afGcZbHULRQoD9F5TJRaV0Rk8Gg/viewform?usp=sf_link"}
        style={{
                      backgroundColor: "#ef0362",
                      borderColor: "#ef0362"
                    }}>Apply</Button>
      </Modal.Footer>
    </Modal>
  );
}

const Careers = () => {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <div className="App">
      <Container>
        <Row>
          <Col md={12} style={{ paddingTop: "12%" }}>
            <header className="App-header">
              <div style={{ display: "inherit" }}>
                <img src={logo} className="App-logo" alt="logo" />
                <p
                  style={{
                    paddingLeft: "10px !important;",
                    fontSize: "48px",
                    margin: "auto",
                    marginLeft: "10px",
                  }}
                >
                  Pay<span style={{ color: "#ef0362" }}>Plus</span>
                </p>
              </div>

              <h3
                style={{
                  width: "80%",
                  textAlign: "center",
                  fontSize: "16px",
                  fontStyle: "italic",
                }}
              >
                We are hiring!
              </h3>

              <Card style={{ width: "80%", marginBottom: "70px" }}>
                <Card.Body>
                  <h6 style={{ textAlign: "left", fontWeight: "700" }}>
                  Social Media Marketing - Intern
                  </h6>
                  <p style={{fontSize: "16px", textAlign: "left"}}>
                  PayPlus is a fintech company with a mission to expand opportunities for social commerce in Africa. 
                  We are looking for a creative and self-motivated marketing intern to join our team.
                  </p>
                  <p style={{ fontSize: "large", textAlign: "start" }}>
                    Responsibilities
                  </p>
                  <ul
                    style={{
                      fontSize: "14px",
                      textAlign: "left",
                      paddingLeft: "25px",
                    }}
                  >
                    <li>
              Designing surveys to collect data 
                    </li>
                    <li>
                    Implement intuitive marketing strategies for brand exposure
                    </li>
                    <li>
                    Researching consumer trends and opinions to inform marketing strategy
                    </li>
                    <li>
                    Analyzing complex research and representing data through graphics
                    </li>
                    <li>Maintain Social Media platforms of the Company to boost social engagements, promotion of our brand and products.</li>
                  </ul>
                  <Row>
                  <Button
                    style={{
                      backgroundColor: "#ef0362",
                      borderColor: "#ef0362",
                      width: "auto",
                      marginLeft: "35px"
                    }}
                    variant="primary"
                    onClick={() => setModalShow(true)}
                  >
                    Learn More
                  </Button>
                  </Row>
                  
                  <MydModalWithGrid
                    show={modalShow}
                    scrollable={true}
                    onHide={() => setModalShow(false)}
                  />
                </Card.Body>
              </Card>
            </header>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export { Careers };
