import React from "react";
import banner from "./payplus_mobile.svg";
import logo from "./logo_icon.png";
import payplus from "./payplus.png";
import "./App.css";
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Image,
} from "react-bootstrap";

function Home() {
  const [data, setData] = React.useState(null);
  const [slogan, setSlogan] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [errors, setErrors] = React.useState({});
  const [showmessage, setShowmessage] = React.useState(false);

  React.useEffect(() => {
    fetch("/api")
      .then((res) => res.json())
      .then(function (data) {
        setData(data.message);
        setSlogan(data.slogan);
      });
  }, []);

  function handleSubmit() {
    let error = handleValidation();
    setShowmessage(error);
    console.log(error);

    let todo = {
      name: "dummy",
      date: Date.now().toString(),
      email: inputValue
  };
  
  fetch('/contact', {
      method: 'POST',
      body: JSON.stringify(todo),
      headers: { 'Content-Type': 'application/json' }
  })
    .then(json => console.log(json));

  }

  function handleValidation() {
    let errors = {};
    let formIsValid = true;

    //Email
    if (!inputValue) {
      formIsValid = false;
      errors["email"] = "Email cannot be empty";
    }

    if (typeof inputValue !== "undefined") {
      let lastAtPos = inputValue.lastIndexOf("@");
      let lastDotPos = inputValue.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          inputValue.indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          inputValue.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Email is not valid";
      }
    }

    setErrors({ email: errors["email"] });
    return formIsValid;
  }

  return (
    <div className="App">
      <Container>
        {/* <Row>
          <FontAwesomeIcon icon={faHome} style={{fontSize: "48px"}}/>
          </Row> */}
        <Row>
          <Col md={6} style={{ paddingTop: "12%" }}>
            <header className="App-header">
              <div style={{display: "inline-flex", padding: "0 20%" }}>
                <img src={logo} className="App-logo" alt="logo" />
                <img src={payplus} className="PayPlus" alt="payplus" />
                {/* <p
                  style={{
                    paddingLeft: "10px !important;",
                    fontSize: "48px",
                    margin: "auto",
                    marginLeft: "10px",
                  }}
                >
                  Pay<span style={{ color: "#ef0362" }}>Plus</span>
                </p> */}
              </div>
              <p style={{ fontSize: "14px", width: "70%" }}>
                {/* A payment app that helps you stay ahead of internet scams so you can shop safely on websites, social media and WhatsApp */}
                We want more people to shop on social media safely. Payment with <strong>PayPlus</strong> keeps your online transactions safe
                especially when you shop on social media or WhatsApp
              </p>
              <p>Get Notified When we Launch</p>
              {showmessage ? <p style={{fontSize: "14px", color: "#ef0362"}}>Thank you, we will send updates</p> : 
              <InputGroup className="mb-3" style={{ width: "80%" }}>
                <FormControl
                  placeholder="Enter your email address"
                  aria-label="Enter your email address"
                  onChange={(e) => setInputValue(e.target.value)}
                  aria-describedby="basic-addon2"
                />
                <InputGroup.Text
                  id="basic-addon2"
                  className="btnFormSend"
                  onClick={handleSubmit}
                >
                  Notify Me
                </InputGroup.Text>
              </InputGroup>
              }
              {!errors["email"]? null : <p style={{color: "red", marginTop: "-1rem", fontSize: "12px", width: "80%",textAlign: "start"}}>{errors["email"]}</p>}
            </header>
          </Col>
          <Col id="imageHolder" md={6} style={{ padding: "6% 0", marginBottom: "70px" }}>
            <Image id="imageItself" src={banner} height="400px" rounded />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export {Home};