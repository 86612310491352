import React from 'react';

import { NavLink, Switch, Route } from 'react-router-dom';

import { Careers } from './Careers';
// import { Contact } from './Contact';
import { Home } from './Home';
import { PrivacyPolicy } from './privacy';

const Main = () => {
    return (
        <Switch>
            <Route exact path='/' component={Home}></Route>
            <Route exact path='/careers' component={Careers}></Route>
            <Route exact path='/privacy' component={PrivacyPolicy}></Route>
            <Route exact path='/*' component={Home}></Route>
        </Switch>
    );
}

export { Main };