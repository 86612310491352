import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';

import { Main } from './Main';
import { Navigation } from './Navigation';

class App extends Component {


  render() {
    return (
      <div >
        <Main />
        <Navigation/>
      </div>
    );
  }
}

export default App;
